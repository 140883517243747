import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

import { Row, Col, Card, CardBody, Button as ReqButton } from "reactstrap"
import { Toast } from 'primereact/toast';

import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Skeleton } from "primereact/skeleton"
import { Dropdown } from "primereact/dropdown"
import { Divider } from "primereact/divider"
import { InputText } from "primereact/inputtext"
import usePayment from "hooks/usePayment"
import useAuth from "hooks/useAuth"
import ChangeRequestModal from "./modals/ChangeRequestModal"
import ExportCSV from "./export/ExportCSV"
import {
  currencyFormatter,
  inputDateTime,
  inputDateTimeHour,
  methodId,
  userAddress,
  debounce,
} from "helpers/functions"
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value"
import DecryptBank from "../../Admin-Support/modals/DecryptBank.js"
const PaymentTable = ({ datas, loading, id, expandedRows, setExpandedRows, lazyState, setLazyState }) => {
  const toast = useRef(null);

  const {
    totalRecords,
    autoWithdrawal,
    paymentStatus,
    setSelectedStatus,
    selectedStatus,
  } = usePayment()
  const [search, setSearch] = useState(id ? id : null)

  const dt = useRef(null)
  const delay = 300

  const checkUser = data => {
    return data?.partner[0]?.payment?.bank_account_name || data?.user[0].name || "deleted user"
  }

  const badgeStatus = data => {
    return (
      <span className={`badge bg-${data.status} text-t-${data.status}`}>
        {data.status}
      </span>
    )
  }
  const copyToClipboard = async data => {
    try {
      console.log(data);
      const id = data.reqId;
      const { protocol, hostname, port } = window.location;

      const pathname = window.location.pathname; // e.g., "/accounting/3231-0105" or "/accounting/"
      const segments = pathname.split('/').filter(segment => segment !== ''); // Filter out empty segments
      let basepath;
      if (segments.length > 0) {
        // Take the first segment and construct the base path
        basepath = `/${segments[0]}/`;
      } else {
        basepath = '/'; // In case of an empty path
      }
      const fullBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${basepath}${id}`;
      await navigator.clipboard.writeText(fullBaseUrl);
      toast.current.show({ severity: 'info', summary: 'Link copied to clipboard' });
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const userEmail = data => {
    return data?.partner[0]?.contact?.contact_email || data?.user[0]?.email || "-"
  }

  const userIP = data => {
    return data?.user[0]?.data?.ip ?? "No Data"
  }
  const paymentAcbe = data => {
    return data?.actrx_id ?? "No Data"
  }
  const isOldPayment = data => {
    const upm = data.upm
    if (upm) {
      return upm.acbe_id == null
    } else {
      return false
    }
  }

  const paymentMethod = data => {
    let method
    switch (data.method) {
      case "banktrf":
        method = "Bank Transfer"
        break
      case "ewallet":
        method = "E-Wallet"
        break
    }
    return method
  }

  const amountFormat = data => {
    if (data.currency === "IDR") return currencyFormatter(data.amount, "IDR")
    return currencyFormatter(data.amount)
  }

  const items = Array.from({ length: lazyState.config.rows }, (v, i) => i)

  const columns = [
    { field: "reqId", header: "ID", body: "" },
    { field: "user", header: "User", body: checkUser },
    { field: "createdAt", header: "Input Date Time", body: inputDateTime },
    { field: "amount", header: "Amount", body: amountFormat },
    { field: "currency", header: "Currency", body: "" },
    { field: "status", header: "Status", body: badgeStatus },
    { field: "method", header: "Method", body: paymentMethod },
    { field: "type", header: "Bank / Wallet", body: "" },

    // { field: 'mtId', header: 'Method Transaction ID / ARN', body: methodId },
  ]

  const onPage = event => {
    let _filters = { ...lazyState }
    console.log(_filters)
    _filters.config = event
    setLazyState(_filters)
  }

  const changeFilter = value => {
    let _filters = { ...lazyState }
    _filters.searchText = value
    setLazyState(_filters)
  }

  // Create a debounced search function
  const debouncedSearch = debounce(changeFilter, delay)

  const onSearchTextChange = e => {
    const value = e.target.value
    setSearch(value)
    debouncedSearch(value)
  }

  const changeStatus = e => {
    setSelectedStatus(e.value)
    let _filters = { ...lazyState }
    _filters.status = e.value
    _filters.config.first = 0 //back to page 1
    setLazyState(_filters)
    setExpandedRows(null)
  }

  const onRowToggle = e => {
    setExpandedRows(e.data)
  }

  const sendStatus = data => {
    switch (data.status) {
      case "open":
        return "process"
        break
      case "pending":
        return "finish"
        break
      default:
        break
    }
  }

  const PDFReceipt = data => {
    window.open(
      process.env.REACT_APP_API_URL + "/api/receipt/" + data._id,
      "_blank"
    )
  }

  const rowExpansionTemplate = data => {
    return (
      <div className="trans-detail">
        <h5>Payment Request ID : {data.reqId} {data?.user_type == 'partner' ? ' (Partner Payment)' : ''}</h5>
        <div className="d-flex flex-row justify-content-around p-3">
          <div style={{ maxWidth: "250px" }}>
            <label>{data?.user_type == 'partner' ? 'Partner' : 'User'} :</label>
            <ul>
              <li>
                ID :
                <Link to={data?.user_type == "partner" ? "/partner-overview" : "/userlist"} state={data?.user_type == "partner" ? data.partner_configuration_id : data.user_id}>
                  {" "}
                  {data?.user_type == "partner" ? data.partner_configuration_id : data.user_id}{" "}
                </Link>
              </li>
              <li>Name : {checkUser(data)}</li>
              <li>Address : {userAddress(data)}</li>
              <li>Email : {userEmail(data)}</li>
              <li>Last IP : {userIP(data)}</li>
            </ul>
            <ReqButton
              color="info"
              onClick={() => {
                PDFReceipt(data)
              }}
            >
              PDF Receipt
            </ReqButton>
          </div>
          <Divider layout="vertical" />
          <div>
            <label>Description :</label>
            <ul>
              <li>Currency : {data.currency}</li>
              <li>Amount : {currencyFormatter(data?.amount, data.currency)}</li>
              <li>Method : {paymentMethod(data)}</li>
              <li>
                {data.method === "banktrf" ? "Bank Name" : "Type"} :{" "}
                {data?.type}
              </li>

              {data?.account_encrypted != null &&
                <li>
                  <DecryptBank data={data} ></DecryptBank>
                </li>
              }

            </ul>
          </div>
          <Divider layout="vertical" />
          <div>
            <p>Request Time : {inputDateTimeHour(data)}</p>
            <p className="mb-0">ARN : {methodId(data)}</p>

            {/* Update Code Here  */}
            {data.withdrawal_method ? (
              <p className="mb-0">Payment Gateway: {data.withdrawal_method}</p>
            ) : null}

            <p className="mb-0">Payment Trx ID : {paymentAcbe(data)}</p>
            {data.error != null && (
              <p className="bold redtext">Payment Error : {data.error}</p>
            )}
            <p>Status : {badgeStatus(data)}</p>
            <div className="d-flex gap-3">
              {(data.status === "open" || data.status === "pending") && (
                <ChangeRequestModal action={"cancel"} data={data} />
              )}
              {autoWithdrawal == true &&
                data.status == "pending" &&
                data.error != null &&
                data.error != "Retrying Payment..." && (
                  <ChangeRequestModal action={"retry"} data={data} />
                )}
              {autoWithdrawal == false &&
                (data.status === "open" || data.status === "pending") && (
                  <ChangeRequestModal action={sendStatus(data)} data={data} />
                )}
              {isOldPayment(data) &&
                (data.status === "open" || data.status === "pending") && (
                  <ChangeRequestModal action={"oldpayment"} data={data} />
                )}
              <Toast ref={toast} />
              <div className="my-2">
                <ReqButton
                  color="info"
                  onClick={() => {
                    copyToClipboard(data)
                  }} >
                  Copy Link
                </ReqButton>
              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <Row className="align-items-center mb-2">
              <Col md={6} className="d-flex flex-row align-items-center gap-3">
                <h4 className="card-title mb-0">Payment Request</h4>
                <Dropdown
                  value={selectedStatus}
                  onChange={changeStatus}
                  options={paymentStatus}
                  optionLabel="name"
                  className="h-1 payment-status-dropdown"
                />
              </Col>
              <Col md={5}>
                <span className="p-input-icon-left float-start float-md-end">
                  <i className="pi pi-search" />
                  <InputText
                    value={search}
                    onChange={onSearchTextChange}
                    placeholder="ID / User"
                    className="p-inputtext-sm user-filter"
                    style={{ width: "240px" }}
                  />
                </span>
              </Col>
              <Col md={1}>
                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                  <ExportCSV lazyState={lazyState} />
                </div>
              </Col>
            </Row>

            <DataTable
              lazy
              value={loading ? items : datas}
              stripedRows
              paginator
              rows={lazyState.config.rows}
              rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
              size={"normal"}
              onPage={onPage}
              totalRecords={totalRecords}
              first={lazyState.config.first}
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
              expandedRows={loading ? null : expandedRows}
              rowExpansionTemplate={rowExpansionTemplate}
              onRowToggle={onRowToggle}
              dataKey={loading ? "" : "reqId"}
              ref={dt}
            >
              <Column expander={true} style={{ width: "3rem" }} />
              {columns.map((col, i) => (
                <Column
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  body={loading ? <Skeleton></Skeleton> : col.body}
                />
              ))}
            </DataTable>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PaymentTable
