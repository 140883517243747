import axiosApi from 'helpers/api_helper';
import useAuth from "hooks/useAuth";
import useUser from "hooks/useUser";
import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';

const UserVerified = ({ data }) => {
    const { updateUser } = useUser()

    const userId = data._id;
    var isVerified = false;
    if (data && data.hasOwnProperty('verified')) {
        isVerified = data.verified;
    }
    const [verified, setVerified] = useState(isVerified);
    const { showToast } = useAuth();

    const handleSwitchChange = async (value) => {
        setVerified(value);

        let newWithdrawalLimit = data.withdrawal_limit;
        if (!value) {
            newWithdrawalLimit = 1000000;
        }

        const verifiedBody = { verified: value, user_id: userId, withdrawal_limit: newWithdrawalLimit };
        const response = await axiosApi.post('/api/admin/support/user-verified', verifiedBody)

        updateUser(data._id, { verified: !data.verified, withdrawal_limit: newWithdrawalLimit });
        showToast(response.data);
    };

    return (
        <div>
            <InputSwitch checked={verified} onChange={(e) => handleSwitchChange(e.value)} />
        </div>
    );
};

export default UserVerified;
