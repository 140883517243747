import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import { simplify } from "helpers/functions";
import makeid from "helpers/random";

const TransactionHistoryExportCSV = ({ lazyState, rowData, options, type, report_type }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYYMM')
    let today = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')

    let filename;
    if (report_type == 'location') {
        filename = `noov-com-${rowData.partner_account} Location Partnership Transactions${options.periodName ? `_${options.periodName}` : ''}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${today}.csv`

    } else if (report_type == 'volume') {
        filename = `noov-com-${rowData.partner_account} Volume Partnership Transactions${options.periodName ? `_${options.periodName}` : ''}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${today}.csv`

    } else {
        filename = `noov-com-${rowData.partner_account} User Partnership Transactions${options.periodName ? `_${options.periodName}` : ''}${lazyState.searchText ? `_${lazyState.searchText}` : ''}_${today}.csv`
    }

    const headers = [
        { label: "ID", key: "TA_ID" },
        { label: "Box", key: "box.name" },
        { label: "User", key: "user.name" },
        { label: "Username", key: "user.username" },
        { label: "Accepted Weight (kg)", key: "TA_UCO_Weight" },
        { label: "Accepted Volume (liter)", key: "TA_UCO_Volume" },
        { label: "Rejected Weight (kg)", key: "TA_Slops_Weight" },
        { label: "Rejected Volume (liter)", key: "TA_Slops_Volume" },
        { label: "Currency", key: 'currency' },
        { label: "Amount", key: "amount" },
        { label: "Start", key: "TA_Start_Time" },
        { label: "End", key: "TA_End_Time" },
        { label: "Address", key: "address.address" },
        { label: "Postcode", key: "address.postcode" },
        { label: "City", key: "address.city" },
        { label: "Country", key: "address.country" },
        { label: "Latitude", key: "address.lat" },
        { label: "Longitude", key: "address.long" },
        { label: "Method", key: "method" },
        { label: "Alcohol Level", key: "extraData.alcohol_level" },
        { label: "Hydrocarbon Level", key: "extraData.hydrocarbon_level" },
        { label: "Raw Max Level", key: "extraData.raw_max_level" },
        { label: "Raw Interface Level", key: "extraData.raw_interface_level" },
    ];

    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])

    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}`;
            if (type == 'monthly') {
                queryParams += `&startDate=${options.startDate}&endDate=${options.endDate}`;
            } else {
                queryParams += `&startDate=${options.startDate}&endDate=${options.endDate}`;
            }
            let baseUrl = ``;
            if (report_type == 'location') {
                queryParams += `&partner_location_id=${rowData?.location_partner?.partner_location_id ?? ''}`
                baseUrl = `/api/admin/partner-transactions/location-partner?${queryParams}`;
            } else if (report_type == 'volume') {
                queryParams += `&user_id=${rowData?.user_id ?? ''}`
                baseUrl = `/api/admin/partner-transactions/volume-partner?${queryParams}`;
            } else {
                queryParams += `&partner_id=${rowData?.user_partner?.partner_id ?? ''}`
                baseUrl = `/api/admin/partner-transactions/user-partner?${queryParams}`;
            }


            const response = await axiosApi.get(baseUrl);
            const result = response.data.result;
            result.forEach(obj => {
                obj.TA_UCO_Weight = obj.UCO_Approved ? simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight) : 0;
                obj.TA_UCO_Volume = obj.UCO_Approved ? simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume) : 0;
                obj.TA_Slops_Weight = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Weight + obj.TA_Slops_Weight);
                obj.TA_Slops_Volume = obj.UCO_Approved ? 0 : simplify(obj.TA_UCO_Volume + obj.TA_Slops_Volume);
                obj.address.lat = obj.address.lat || obj?.box?.location?.latitude || "";
                obj.address.long = obj.address.long || obj?.box?.location?.longitude || "";
                obj.address.address = obj.address?.address?.replace(/\n/g, ', ') || "";
                obj.currency = rowData?.user?.currency
            })
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
            console.log(response.data)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default TransactionHistoryExportCSV