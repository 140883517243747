import React, { useEffect, useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from "reactstrap";
import { currencyFormatter, simplify } from "helpers/functions";
import ReactApexChart from 'react-apexcharts';
import ChartistGraph from "react-chartist";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { MONTHS_IN_YEAR } from 'constants/value';
import { date } from "yup/lib/locale";

const ReferralModal = ({ referral, selectedPeriod, options }) => {
    const axiosApi = useAxiosPrivate();
    const [graphOptions, setGraphOptions] = useState(null)
    const [series, setSeries] = useState([
        {
            name: 'Total User',
            data: [],
        }
    ])
    const [totalReferral, setTotalReferral] = useState(0)
    const [totalTransaction, setTotalTransaction] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalVolume, setTotalVolume] = useState(0)
    const [conversionRate, setConversionRate] = useState(0)

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        const getReports = async () => {
            try {
                let isMonth = true;
                let date1 = true;
                let date2 = true;

                function convertToLocalTime(date) {
                    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
                    return localDate;
                }
                function getDatesInRange(startDate, endDate) {
                    const dateArray = [];
                    let currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                        dateArray.push(new Date(currentDate));
                        currentDate.setDate(currentDate.getDate() + 1);
                    }
                    return dateArray;
                }
                function getMonthInRange(startDate, endDate) {
                    const dateArray = [];
                    let currentDate = new Date(startDate);

                    while (currentDate <= endDate) {
                        dateArray.push(new Date(currentDate));
                        currentDate.setMonth(currentDate.getMonth() + 1);
                    }
                    return dateArray;
                }

                const response = await axiosApi.get(`/api/admin/marketing/referral/report?startDate=${options.startDate}&endDate=${options.endDate}&code=${referral.code}`, { signal });
                setTotalReferral(response.data.totalReferralRecords)
                setConversionRate(response.data.conversionRate)
                setTotalTransaction(response.data.totalTransaction)
                setTotalAmount(response.data.totalUCOAmount)
                setTotalVolume(response.data.totalUCO)
                let reportRaw = response.data.reports

                // console.log('first', reportRaw[0]);
                // console.log('last', reportRaw[reportRaw.length - 1]);
                let formattedDates = [];
                // if (selectedPeriod == 'today') {
                //     isMonth = false;
                //     formattedDates = [convertToLocalTime(new Date(options.startDate)).toLocaleDateString().split('T')[0]]
                // } 
                // else if (selectedPeriod == 'all'){

                // }
                // else if (selectedPeriod == 'year') {
                //     isMonth = true;

                //     const date1 = convertToLocalTime(new Date(options.startDate)).toString();
                //     const date2 = convertToLocalTime(new Date(options.endDate)).toString();

                //     const datesInRange = getMonthInRange(new Date(date1), new Date(date2));
                //     formattedDates = datesInRange.map(date => {
                //         const year = date.getFullYear();
                //         const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero to month
                //         return `${year}-${month}`;
                //     });
                // }
                // else {
                isMonth = false;
                if (selectedPeriod == 'all') {
                    if (reportRaw.length > 0) {
                        date1 = convertToLocalTime(new Date(reportRaw[0].createdAt)).toString();
                        if (reportRaw.length == 1) {
                            const date1Raw = new Date(reportRaw[0].createdAt)
                            date1Raw.setHours(23, 59, 59, 999)
                            const date2Raw = new Date(date1Raw);
                            date2Raw.setDate(date2Raw.getDate() + 1);
                            date2 = convertToLocalTime(new Date(date2Raw)).toString();
                        } else {
                            date2 = convertToLocalTime(new Date(reportRaw[reportRaw.length - 1].createdAt)).toString();
                        }
                    }
                } else {
                    date1 = convertToLocalTime(new Date(options.startDate)).toString();
                    date2 = convertToLocalTime(new Date(options.endDate)).toString();
                }
                if (reportRaw.length > 0) {
                    const datesInRange = getDatesInRange(new Date(date1), new Date(date2));
                    formattedDates = datesInRange.map(date => date.toLocaleDateString().split('T')[0]);
                }
                // }

                let referralCount = {};
                let nonReferralCount = {};
                let dateList = [];
                if (formattedDates.length > 0) {
                    formattedDates.forEach(date => {
                        dateList[date] = date;
                        referralCount[date] = 0;
                        nonReferralCount[date] = 0;
                    });
                }
                if (reportRaw.length > 0) {
                    reportRaw.forEach(element => {
                        let createdDate;
                        if (isMonth) {
                            let rawDate = new Date(element.createdAt);
                            let year = rawDate.getFullYear();
                            let month = (rawDate.getMonth() + 1).toString().padStart(2, '0'); // Add leading zero to month if necessary
                            createdDate = `${year}-${month}`;
                        } else {
                            createdDate = new Date(element.createdAt).toLocaleDateString().split('T')[0];
                        }

                        if (!dateList[createdDate]) {
                            dateList[createdDate] = createdDate;
                        }
                        if (element.referral_code == '' || element.referral_code == null) {
                            //condition for user doesn't have referral
                            if(referral.code == '' || referral.code == null){
                                if (nonReferralCount[createdDate]) {
                                    nonReferralCount[createdDate] += 1;
                                } else {
                                    nonReferralCount[createdDate] = 1;
                                }
                            }else{
                                if (!nonReferralCount[createdDate]) {
                                    nonReferralCount[createdDate] = 0;
                                }
                            }
                            if (!referralCount[createdDate]) {
                                referralCount[createdDate] = 0;
                            }
                        } else if ((referral.code == '' || referral.code == null)) {
                            //conditon for selected partner is all
                            if (referralCount[createdDate]) {
                                referralCount[createdDate] += 1;
                            } else {
                                referralCount[createdDate] = 1;
                            }
                            if (!nonReferralCount[createdDate]) {
                                nonReferralCount[createdDate] = 0;
                            }
                        } else if (element.referral_code == referral.code) {
                            //condition for partner is selected, and user has same referral with selected partner
                            if (referralCount[createdDate]) {
                                referralCount[createdDate] += 1;
                            } else {
                                referralCount[createdDate] = 1;
                            }
                            if (!nonReferralCount[createdDate]) {
                                nonReferralCount[createdDate] = 0;
                            }
                        }

                    });
                }
                let myList = dateList ? Object.values(dateList) : []

                // console.log('referralCount', referralCount);
                // console.log('nonReferralCount', nonReferralCount);
                // console.log('dateList', myList);

                if (response.data.reports.length > 0) {
                    setSeries([
                        {
                            name: 'Total User With Referral',
                            data: Object.values(referralCount),
                        },
                        {
                            name: 'Total User Non Referral',
                            data: Object.values(nonReferralCount),
                        }
                    ]);
                    setGraphOptions(
                        {
                            colors: ['#70ad47', '#619A9A'],
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                type: 'area',
                                stroke: {
                                    curve: 'smooth',
                                    width: 0.1,
                                },
                            },
                            legend: {
                                show: true
                            },

                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2,
                            },
                            grid: {
                                borderColor: '#f8f8fa',
                                row: {
                                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: myList,
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                }
                            },
                            // yaxis: {
                            //     labels: {
                            //         formatter: (value) => {
                            //             return simplify(value)
                            //         }
                            //     }
                            // }
                        }
                    )
                } else {
                    setSeries([])
                    referralCount = {};
                    nonReferralCount = {};
                    dateList = [];
                    setGraphOptions(
                        {
                            colors: ['#70ad47', '#619A9A'],
                            chart: {
                                zoom: {
                                    enabled: false
                                },
                                toolbar: {
                                    show: false,
                                },
                                type: 'area',
                                stroke: {
                                    curve: 'smooth',
                                    width: 0.1,
                                },
                            },
                            legend: {
                                show: true
                            },

                            dataLabels: {
                                enabled: false
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 2,
                            },
                            grid: {
                                borderColor: '#f8f8fa',
                                row: {
                                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: myList,
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false
                                }
                            },
                            // yaxis: {
                            //     labels: {
                            //         formatter: (value) => {
                            //             return simplify(value)
                            //         }
                            //     }
                            // }
                        }
                    )

                }
            } catch (err) {
                console.log(err);
            }
        }
        getReports();

        return () => {
            controller.abort();
        }

    }, [referral, selectedPeriod]);

    return (
        <div>
            <Row>
                <Col lg={9}>
                    <h4 className="text-center">Daily Referral Code Redemptions</h4>
                    {graphOptions && <ReactApexChart options={graphOptions} series={series} type="area" height="250" />
                    }
                </Col>
                <Col lg={3} className="d-flex flex-column justify-content-center">
                    <Row>
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">Total Referral</p>
                                <h3>{currencyFormatter(totalReferral)} Users</h3>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">Conversion Rate</p>
                                <h3>{parseFloat((conversionRate * 100).toFixed(1))} %</h3>
                            </div>
                        </Col>
                        <hr />
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">Total Amount</p>
                                <h3>Rp {currencyFormatter(totalAmount, 'IDR')}</h3>
                                <p className="text-muted mb-4">
                                    {totalTransaction} Transactions
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="text-center">
                                <p className="text-muted mb-4">Total Volume</p>
                                <h3>{simplify(totalVolume)} L</h3>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

export default ReferralModal
