import React from "react"

const CheckboxField = ({ id, value, label, onChange, checked }) => {
    return (
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                value={value}
                id={`checkField${id}`}
                onChange={onChange}
                checked={checked}
            />
            <label className="form-check-label" htmlFor={`checkField${id}`}>
                {label}
            </label>
        </div>
    )
}

export default CheckboxField
