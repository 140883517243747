import React, { useState } from "react"
import moment from "moment"
import UserExportCSV from "./export/NotificationLogExportCSV";
import UserNotificationModal from "./modals/UserReadNotificationModal";

import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useNotifLog from "hooks/useNotifLog";
import { Skeleton } from "primereact/skeleton";
import { InputText } from 'primereact/inputtext';
import { debounce } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";

const NotificationLogTable = ({ loading, id, lazyState, setlazyState }) => {
    const { notifLogs, totalRecords } = useNotifLog();

    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const [search, setSearch] = useState(id || '')
    // Create a debounced search function

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const createdAt = (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss')
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const badgeStatus = (data) => {
        var badgetText = 'danger'
        var statusText = 'fail'
        if (data.status == true) {
            statusText = 'success'
            badgetText = 'success'
        }
        return (
            <span className={`badge bg-${badgetText} text-t-${badgetText}`}>
                {statusText}
            </span>
        )
    }

    //akan digunakan untuk popup list user, masih di hold sekarang
    const readUserList = (data) => {
        return (
            <UserNotificationModal data={data} />
        );
    }

    const urlBody = (data) => {
        return data?.payload ? (
            <Link to={data.payload}>
                <i className="fa fa-link" />
            </Link>
        ) : (
            '-'
        );
    }

    const columns = [
        { field: '', header: '#', body: incrementId, className: 'text-end' },
        { field: 'title', header: 'Title', body: '', className: '' },
        { field: 'body', header: 'Message', body: '', className: '' },
        { field: 'payload', header: 'Url', body: urlBody, className: '' },
        { field: 'user_count', header: 'Sent to user', body: '', className: "text-end" },
        { field: 'user_read_count', header: 'Read by user', body: readUserList, className: "text-end" },
        { field: 'admin_name', header: 'Sender', body: '', className: '' },
        { field: 'createdAt', header: 'Date', body: createdAt, className: '' },
        { field: 'status', header: 'Status', body: badgeStatus, className: '' },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} xs={12} className="d-flex flex-row align-items-center gap-3">
                                <h4 className="card-title mb-0">Broadcast Notification Logs</h4>
                            </Col>
                            <Col md={5} xs={8}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Title / Message / Url" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={4}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <UserExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : notifLogs}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                        >
                            {columns.map((col, i) => (
                                <Column key={col.field} field={col.field} header={col.header} bodyClassName={col.className} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default NotificationLogTable
