import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from 'react-router-dom';
import { connect } from "react-redux";
import useVersionChecker from './hooks/useVersionChecker';
import ReloadModal from './modals/ReloadModal';


// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"; 

// Import scss
import "./assets/scss/theme.scss";

import PersistLogin from 'helpers/PersistLogin';

import { Toast } from 'primereact/toast';
import useAuth from 'hooks/useAuth';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const App = () => {
  const { toast } = useAuth();
  const newVersionAvailable = useVersionChecker();

  const handleReload = () => {
    window.location.reload();
  };
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <NonAuthLayout>
                  {route.component}
                </NonAuthLayout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>

        <Route element={<PersistLogin/>}>
          <Route>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                      {route.component}
                  </Authmiddleware>
                  }
                key={idx}
                exact={true}
              />
            ))}
          </Route>
        </Route>
        
      </Routes>
      <Toast ref={toast} />
      {newVersionAvailable && <ReloadModal onReload={handleReload} />}
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
