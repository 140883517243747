import axiosApi from 'helpers/api_helper';
import useAuth from "hooks/useAuth";
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const WithdrawalLimit = ({ data }) => {

    const { updateUser } = useUser()

    const { showToast } = useAuth();

    const [modal, setModal] = useState(false);
    const [userWithdrawalLimit, setUserWithdrawalLimit] = useState(data.withdrawal_limit);
    const [isVerified, setIsVerified] = useState(data.verified);

    const userId = data._id;

    const toggleModal = () => setModal(!modal);

    useEffect(() => {
        setIsVerified(data.verified);
        setUserWithdrawalLimit(data.withdrawal_limit);
    }, [data.verified, data.withdrawal_limit, data]);

    const handleConfirm = async () => {
        const body = { withdrawal_limit: userWithdrawalLimit, user_id: userId };
        
        try {
            const response = await axiosApi.post('/api/admin/support/user-withdrawal/', body);
            updateUser(userId, { withdrawal_limit: userWithdrawalLimit });
            showToast(response.data);
            toggleModal();
        } catch (error) {
            console.error(error);
            toggleModal();
        }
    };

    return (
        <>
            <input
                type="number"
                className="form-control"
                style={{ width: '120px' }}
                onChange={(e) => setUserWithdrawalLimit(Number(e.target.value))}
                value={userWithdrawalLimit}
                disabled={!isVerified}
            />
            <Button
                color="primary"
                className=""
                onClick={toggleModal}
                disabled={!isVerified}
                >
                    Save
            </Button>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Save Withdrawal Limit</ModalHeader>
                <ModalBody>
                    Are you sure want to save the limit of withdrawal for this user?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default WithdrawalLimit