import { useState, useEffect } from 'react';
import axios from 'axios';

const useVersionChecker = () => {
  const [version, setVersion] = useState(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get('/version.json');
        const serverVersion = response.data.version;

        if (version && version !== serverVersion) {
          setNewVersionAvailable(true);
        } else if (!version) {
          setVersion(serverVersion);
        }
      } catch (error) {
        console.error('Failed to fetch version:', error);
      }
    };

    fetchVersion();
    const interval = setInterval(fetchVersion, 5 * 60 * 1000); // Every 5 minutes

    return () => clearInterval(interval);
  }, [version]);

  return newVersionAvailable;
};

export default useVersionChecker;
