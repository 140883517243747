import { useState, useRef, useCallback, useEffect } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter, currencyFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import usePartner from "hooks/usePartner";
import DeletePartner from "./modals/DeletePartner";
import { formatThousandSeparator } from "helpers/functions";
import '../../../assets/css/style.css'
import TransactionHistoryModal from "./modals/TransactionHistoryModal";
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from 'helpers/random';
import SortHeader from "pages/SortHeader";
import DataExportCSV from "./exports/DataExportCSV";

const OverviewTable = ({ id, searchId, lazyState, setlazyState, setSortField }) => {
    const lazyStateRef = useRef(lazyState); // Create a ref to keep track of the latest lazyState

    useEffect(() => {
        lazyStateRef.current = lazyState;
    }, [lazyState]);
    const { showToast } = useAuth();
    const [search, setSearch] = useState(id || searchId || '')
    const [editRowId, setEditRowId] = useState(null);
    const [editValue, setEditValue] = useState('');
    const axiosApi = useAxiosPrivate();
    const [sortStates, setSortStates] = useState({
        total_collected_volume: 'idle',
        volume_total_collected_volume: 'idle',
        location_total_collected_volume: 'idle'
    });
    const { overviewTotalRecords, setPartnerLoading, partnerLoading, overviews, setPartnerRefresh } = usePartner();

    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 1000;


    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const changeFilter = (value) => {
        const _filters = { ...lazyStateRef.current }; // Get the latest lazyState from the ref
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = useCallback(debounce(changeFilter, delay), []);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        setPartnerLoading(true);
        debouncedSearch(value);
    }
    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    const actualVolume = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="volume" />
                {" / "}
                {formatThousandSeparator(String(data.partner_volume?.expected_volume ?? 0))}
            </>
        );
    }
    const actualUser = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="user" />
                {" / "}
                {formatThousandSeparator(String(data.user_partner?.user_count ?? 0))}
            </>
        );
    }
    const actualLocation = (data) => {
        return (
            <>
                <TransactionHistoryModal rowData={data} options={lazyState} type="monthly" report_type="location" />
                {" / "}
                {formatThousandSeparator(String(data.location_partner?.location_count ?? 0))}
            </>
        );
    }
    const handleKeyDown = async (e, rowData) => {
        if (e.key === 'Enter') {
            const values = { events: editValue }
            const response = await axiosApi.put(`/api/admin/partner-configurations/${editRowId}/events`, values);
            setEditRowId(null);
            setEditValue('');
            showToast(response.data);
            setPartnerRefresh(makeid(5));
        }
    };

    const handleRowEditInit = (rowData) => {
        setEditRowId(rowData.id); // Assuming each data row has a unique 'id' property
        setEditValue(rowData.events_count);
    };
    const updateEvents = (rowData, column) => {
        if (editRowId === rowData.id && column.field === 'events_count') {
            return (
                <InputText value={editValue}
                    onChange={(e) => {

                        const inputValue = e.target.value;
                        const regex = /^[0-9.]*$/; // Regular expression to allow only numbers and the decimal point
                        if (regex.test(inputValue)) {
                            setEditValue(inputValue);
                        }
                    }}
                    onBlur={(e) => {

                        setEditRowId(null);
                        setEditValue('');

                    }}
                    onKeyDown={(e) => handleKeyDown(e, rowData)}
                    className=''
                    autoFocus
                />

            );
        } else {
            return (
                <div onClick={() => handleRowEditInit(rowData)} style={{ color: '#70ad47', cursor: 'pointer' }}>
                    {rowData.events_count || 0}
                </div>
            );
        }
    };

    const adminAction = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div className="my-2">
                    <a href={`/partner-overview/edit-partner/${data.id}`}><button class="btn btn-primary">
                        <i className="fas fa-pen"></i>
                    </button></a>
                </div>
                <div>
                    <DeletePartner rowData={data} />
                </div>
            </div>
        )
    }
    const headerVolume = () => {
        return <SortHeader title={<div><div>Volume</div><div>(Actual / Exp.)</div></div>} sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'total_collected_volume'} />;
    }

    const headerUser = () => {
        return <SortHeader title='Volume / User' sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'volume_total_collected_volume'} />;
    }

    const headerLocation = () => {
        return <SortHeader title='Volume / Location' sortStates={sortStates} setSortStates={setSortStates} setSortField={setSortField} field={'location_total_collected_volume'} />;
    }
    const bodyPartnerName = (data) => {
        const partnerName = data.partner_account;
        const partnerTypeList = data.partner_type_list || [];

        const formattedPartnerTypes = (
            <div>
                {partnerTypeList.map((type) => {
                    if (type === 'location_partner') {
                        return <span className="badge bg-secondary me-2" style={{ color: '#6610f2' }}  key={type}>Loc</span>;
                    } else if (type === 'user_partner') {
                        return <span className="badge bg-active text-t-active me-2" key={type}>User</span>;
                    } else if (type === 'volume_partner') {
                        return <span className="badge bg-offline text-t-offline me-2" key={type}>Vol</span>;
                    }
                    return null;
                })}
            </div>
        );

        return (
            <div>
                <span>{partnerName}</span>
                {formattedPartnerTypes}
            </div>
        );
    };

    const AppUsername = (data) => {
        if(data?.username && data?.username!='-'){
            return <Link to={`/userlist/${data?.username}`}> {data?.username} </Link>
        }else{
            return data?.username;
        }
    }
    const columns = [
        { field: '', header: '#', body: incrementId },
        { field: 'partner_account', header: 'Partner Name', body: bodyPartnerName, className: '' },
        { field: 'username', header: 'Username', body: AppUsername, className: '' },
        { field: 'partner_volume.total_collected_volume', header: (<><span>Volume<br />(Actual / Exp.)</span></>), body: actualVolume, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'user_partner.total_collected_volume', header: (<><span>Volume/<br/>User</span></>), body: actualUser, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'location_partner.total_collected_volume', header: (<><span>Volume/<br/>Location</span></>), body: actualLocation, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' }, sortable: true },
        { field: 'events_count', header: 'Events', body: updateEvents, className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: '', header: 'Reports', body: (data) => (data.reports_count) ? `${data.reports_count}` : '0', className: 'text-end', headerClassName: 'text-end', style: { whiteSpace: 'nowrap' } },
        { field: 'action', header: 'Action', body: adminAction },
    ]

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center">
                            <Col md={6} xs={12} className="d-flex flex-row align-items-center gap-3 mb-2">
                                <a href="/partner-overview/add-partner"><button class="btn btn-primary">Add Partner</button></a>
                            </Col>
                            <Col md={5} xs={8} className="mb-2">
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Partner Name / Username" className='p-inputtext-sm user-filter' style={{ width: '240px' }} />
                                </span>
                            </Col>
                            <Col md={1} xs={4} className="mb-2">
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <DataExportCSV lazyState={lazyState} />
                                </div>
                            </Col>
                        </Row>
                        <DataTable
                            removableSort
                            // lazy
                            value={partnerLoading ? items : overviews}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            // onPage={onPage}
                            totalRecords={overviewTotalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={partnerLoading ? '' : 'id'}
                            defaultSortOrder={-1}
                        >
                            {/* <Column expander={true} style={{ width: '3rem' }} /> */}
                            {columns.map((col, i) => (
                                <Column
                                    key={col.field}
                                    sortable={col.sortable}
                                    field={col.field}
                                    header={col.header}
                                    headerClassName={col.headerClassName}
                                    style={col.style}
                                    body={partnerLoading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default OverviewTable