import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
import usePartner from "hooks/usePartner";
const DataExportCSV = ({ lazyState }) => {
    const { entityList, sectorList } = usePartner();
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()

    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Location_Partner_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { key: 'partner_account', label: 'Partner Account Name', body: '', className: '' },
        { key: 'contact.entity_type', label: 'Entity Type', body: '', className: '' },
        { key: 'contact.sector_type', label: 'Sector Type', body: '', className: '' },
        { key: 'contact.contact_ktp', label: 'KTP', body: '', className: '' },
        { key: 'contact.contact_npwp', label: 'NPWP', body: '', className: '' },
        { key: 'contact.legal_name', label: 'Legal Name', body: '', className: '' },
        { key: 'contact.contact_name', label: 'Contact Name', body: '', className: '' },
        { key: 'contact.contact_address', label: 'Street Address', body: '', className: '' },
        { key: 'contact.contact_country', label: 'Country', body: '', className: '' },
        { key: 'contact.contact_region', label: 'Region', body: '', className: '' },
        { key: 'contact.contact_city', label: 'City', body: '', className: '' },
        { key: 'contact.contact_postalcode', label: 'Postal Code', body: '', className: '' },
        { key: 'contact.contact_email', label: 'Contact Email', body: '', className: '' },
        { key: 'contact.contact_phone', label: 'Contact Phone Number', body: '', className: '' },
        { key: 'contact.sustainability_report_email', label: 'Sustainability Report Email', body: '', className: '' },
        { key: 'contact.monthly_report_email', label: 'Monthly Report Email', body: '', className: '' },
        { key: 'partner_types', label: 'Type', body: '', className: '' },
        { key: 'lang', label: 'Language', body: '', className: '' },
        { key: 'username', label: 'App Username', body: '', className: '' },
        { key: 'is_fixed_price', label: 'Fixed Price (Yes / No)', body: '', className: '' },
        { key: 'detail.fixed_monthly_price', label: 'Location Price / Liter', body: '', className: '' },
        { key: 'fixed_price', label: 'Volume Price / Liter', body: '', className: '' },
        { key: 'expected_volume', label: 'Exp. Monthly Volume', body: '', className: '' },
        { key: 'direct_payment_enabled', label: 'Direct Payment (Yes / No)', body: '', className: '' },
        { key: 'country', label: 'Bank Country', body: '', className: '' },
        { key: 'bank_name', label: 'Bank Name', body: '', className: '' },
        { key: 'bank_account_number', label: 'Account Number', body: '', className: '' },
        { key: 'bank_account_name', label: 'Account Name', body: '', className: '' },
    ]
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
            const response = await axiosApi.get(`/api/admin/partner-locations?${queryParams}`);
            const result = response.data.result;
            result.forEach(obj => {
                obj.total_volume = (obj.total_collection_volume ?? 0) ;
                obj.box_count = obj?.detail?.box_ids?.length || 0;
                obj.compensation = obj?.detail?.compensation ?? '-';
                obj.fixed_monthly_price = obj?.detail?.fixed_monthly_price ?? '-';
                const { 
                    entity_type,
                    sector_type,
                    contact_ktp,
                    contact_npwp,
                    legal_name,
                    contact_name,
                    contact_address,
                    contact_country,
                    contact_region,
                    contact_city,
                    contact_postalcode,
                    contact_email,
                    contact_phone,
                    sustainability_report_email,
                    monthly_report_email,
                } = obj.contact ?? {};
                const entity = entityList.find((item) => item.value === entity_type);
                const sector = sectorList.find((item) => item.value === sector_type);
                obj.contact_name = contact_name ?? '-';
                obj.contact_email = contact_email ?? '-';
                obj.contact_phone = contact_phone ?? '-';
                obj.contact_address = contact_address ?? '-';
                obj.contact_country = contact_country ?? '-';
                obj.contact_region = contact_region ?? '-';
                obj.contact_city = contact_city ?? '-';
                obj.contact_postalcode = contact_postalcode ?? '-';
                obj.contact_ktp = contact_ktp ?? '-';
                obj.contact_npwp = contact_npwp ?? '-';
                obj.legal_name = legal_name ?? '-';
                obj.entity_type = entity ? entity.abbr : obj.type;
                obj.sector_type = sector ? sector.name : obj.sector_type;
                obj.sustainability_report_email = sustainability_report_email ?? '-';
                obj.monthly_report_email = monthly_report_email ?? '-';
                

            });
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV