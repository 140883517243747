import { CSVLink } from "react-csv"
import { Button } from 'primereact/button';
import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import moment from "moment";
import makeid from "helpers/random";
const DataExportCSV = ({ lazyState }) => {
    const [dataReq, setDataReq] = useState([])
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false)
    const axiosApi = useAxiosPrivate();
    const csvRef = useRef()
   
    let date = moment(new Date).format('YYYY_MM_DD_HH_mm_ss')
    let filename = `Partner_Overview_List_${date}${lazyState.searchText ? `_${lazyState.searchText}` : ''}${lazyState.periodName ? `_${lazyState.periodName}` : ''}.csv`

    const headers = [
        { key: 'partner_account', label: 'Partner Account Name', body: '', className: '' },
        { key: 'entity_type', label: 'Entity Type', body: '', className: '' },
        { key: 'sector_type', label: 'Sector Type', body: '', className: '' },
        { key: 'contact_ktp', label: 'KTP', body: '', className: '' },
        { key: 'contact_npwp', label: 'NPWP', body: '', className: '' },
        { key: 'legal_name', label: 'Legal Name', body: '', className: '' },
        { key: 'contact_name', label: 'Contact Name', body: '', className: '' },
        { key: 'contact_address', label: 'Street Address', body: '', className: '' },
        { key: 'contact_country', label: 'Country', body: '', className: '' },
        { key: 'contact_region', label: 'Region', body: '', className: '' },
        { key: 'contact_city', label: 'City', body: '', className: '' },
        { key: 'contact_postalcode', label: 'Postal Code', body: '', className: '' },
        { key: 'contact_email', label: 'Contact Email', body: '', className: '' },
        { key: 'contact_phone', label: 'Contact Phone Number', body: '', className: '' },
        { key: 'sustainability_report_email', label: 'Sustainability Report Email', body: '', className: '' },
        { key: 'monthly_report_email', label: 'Monthly Report Email', body: '', className: '' },
        { key: 'partner_types', label: 'Type', body: '', className: '' },
        { key: 'lang', label: 'Language', body: '', className: '' },
        { key: 'username', label: 'App Username', body: '', className: '' },
        { key: 'is_fixed_price', label: 'Fixed Price (Yes / No)', body: '', className: '' },
        { key: 'price_per_liter', label: 'Price / Liter', body: '', className: '' },
        { key: 'expected_monthly_volume', label: 'Exp. Monthly Volume', body: '', className: '' },
        { key: 'direct_payment_enabled', label: 'Direct Payment (Yes / No)', body: '', className: '' },
        { key: 'country', label: 'Bank Country', body: '', className: '' },
        { key: 'bank_name', label: 'Bank Name', body: '', className: '' },
        { key: 'bank_account_number', label: 'Account Number', body: '', className: '' },
        { key: 'bank_account_name', label: 'Account Name', body: '', className: '' },
    ]
    useEffect(() => {
        if (dataReq.length > 0) {
            csvRef.current.link.click()
        } else {
            console.log('data is empty')
        }
    }, [refresh])
    const fetchDataReq = async () => {
        setLoading(true);
        try {
            let queryParams = `searchText=${lazyState.searchText}&startDate=${lazyState.startDate}&endDate=${lazyState.endDate}`;
            const response = await axiosApi.get(`/api/admin/partner-configurations/export-all?${queryParams}`);
            const result = response.data.result;
            console.log("ini result export overview", result)
            result.forEach(obj => {
                obj.is_fixed_price = obj.partner_volume.is_fixed_price ? 'Yes' : 'No';
                obj.price_per_liter = obj.partner_volume.fixed_price;
                obj.expected_monthly_volume = obj.partner_volume.expected_volume;
                obj.direct_payment_enabled = obj.partner_volume.direct_payment_enabled ? 'Yes' : 'No';
            });
            setDataReq(result)
            setRefresh(makeid(5))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }
    return (
        <>
            <Button
                type="button"
                icon={`mdi mdi-24px ${loading === true ? 'mdi-loading mdi-spin' : 'mdi-file'}`}
                rounded
                tooltip="Export as CSV"
                tooltipOptions={{ position: 'top' }}
                onClick={() => fetchDataReq()}
            />
            <CSVLink
                data={dataReq}
                style={{ display: 'none' }}
                ref={csvRef}
                filename={filename}
                headers={headers}
            >
            </CSVLink>
        </>

    )
}

export default DataExportCSV