import { createContext, useState } from "react";

const AdminContext = createContext({});

export const AdminProvider = ({children}) => {
    const [refresh, setRefresh] = useState('');
    const [admins, setAdmins] = useState();
    const [adminLoading, setAdminLoading] = useState(false);
    const levelOptions = [
        { label: "Admin", value: "superadmin" },
        { label: "Finance", value: "finance" },
        { label: "Engineer", value: "engineer" },
        { label: "Support", value: "support" }
    ];
    const methodList = [
        {
            name: "Whatsapp",
            key: "whatsapp",
        },
        {
            name: "E-mail",
            key: "email",
        }
    ];

    return (
        <AdminContext.Provider value={{ refresh, setRefresh, levelOptions, admins, setAdmins, adminLoading, setAdminLoading, methodList}}>
            {children}
        </AdminContext.Provider>
    )
}

export default AdminContext;