import React, { useEffect, useState } from "react"
import LoadingOverlay from 'react-loading-overlay';
import ConfirmationModal from "./modals/ConfirmationModal";

import useAxiosPrivate from "hooks/useAxiosPrivate"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputSwitch } from "primereact/inputswitch"
import useAuth from "hooks/useAuth"

const GeneralSettings = () => {
  const { showToast } = useAuth()
  const [refresh, setRefresh] = useState(null)
  // const [withdrawal, setWithdrawal] = useState(false)
  
  const [rawData, setRawData] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [version, setVersion] = useState({
    android: "20",
    ios: "20",
  })
  const [customerService,setCustomerService] = useState({
    id:"+62",
    sg:"+65",
    my:"+60",
  });

  const axiosApi = useAxiosPrivate()

  const getAppSettings = async () => {
    try {
      // if (isSuccess) {
      //   return;
      // }

      const response = await axiosApi.get("/api/admin/setting/get-app-settings")
      if (response.data.setting) {
        const default_setting = {
          setting: {
            // auto_withdrawal: true,
            app_min_version: 0,
            customer_service: {
              id:"+62",
              sg:"+65",
              my:"+60",
            }
          },
        }

        // Assuming response.data has the same structure as default_setting
        Object.assign(default_setting.setting, response.data.setting)

        // const { auto_withdrawal } = default_setting.setting
        const { app_min_version,customer_service } = default_setting.setting
        const defaultData ={}
        defaultData.version = default_setting.setting.app_min_version;
        defaultData.customer_service = default_setting.setting.customer_service;
        // defaultData.auto_withdrawal = default_setting.setting.auto_withdrawal??false;
        setRawData(defaultData)

        // if (auto_withdrawal) {
        //   setWithdrawal(auto_withdrawal)
        // }
        if (app_min_version) {
          setVersion(app_min_version)
        }
        if (customer_service) {
          setCustomerService(customer_service)
        }
        
      }
      setIsSuccess(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.state) {
        let response = error.response.data;
        response.state = "error";
        response.toast = true;
        showToast(response);
        setIsSuccess(true);
      } else {
        let response = {};
        response.state = "error";
        response.toast = true;
        response.message = "Internal Server Error"
        showToast(response);
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      getAppSettings()
    }, 300);
  }, [refresh])

  const handleConfirm = async () => {
    try {
      const values = modalData
      const {
        android_v,
        ios_v,
        whatsappIndonesia,
        whatsappSingapore,
        whatsappMalaysia,
      } = values

      const app_min_version_obj = { android: android_v, ios: ios_v }

      const customer_service_obj = {
        id: whatsappIndonesia ?? "+62",
        sg: whatsappSingapore ?? "+65",
        my: whatsappMalaysia ?? "+60",
      }

      const obj = {
        // auto_withdrawal: withdrawal,
        app_min_version: app_min_version_obj,
        customer_service : customer_service_obj
      }

      const response = await axiosApi.post(
        "/api/admin/setting/update-app-settings",
        obj
      )
      showToast(response.data)
      const {
        random,
        setting: {
          // auto_withdrawal,
          app_min_version,
          customer_service
        },
      } = response.data
      setRefresh(random)
      // setWithdrawal(auto_withdrawal)
      setVersion(app_min_version)
      setCustomerService(customer_service)
      setIsModalOpen(false)
    } catch (error) {
      if (error.response && error.response.data) {
        validation.setErrors(error.response.data)
      } else {
        console.error(error)
      }
    }
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setModalData(null)
  }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      android_v: version.android,
      ios_v: version.ios,
      whatsappIndonesia: customerService.id,
      whatsappSingapore: customerService.sg,
      whatsappMalaysia: customerService.my,
    },
    validationSchema: Yup.object().shape({
      android_v: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      ios_v: Yup.string()
        .matches(/^(0|[1-9][0-9]*)(\.\d+)?$/, "Invalid input")
        .required("This field is required"),
      whatsappIndonesia: Yup.string()
        .matches(/^\+[0-9]+$/, "Invalid phone number"),
      whatsappSingapore: Yup.string()
        .matches(/^\+[0-9]+$/, "Invalid phone number"),
      whatsappMalaysia: Yup.string()
        .matches(/^\+[0-9]+$/, "Invalid phone number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsModalOpen(true)
      // const opts = { ...values, withdrawal: withdrawal }
      setModalData(values)
    },
  })

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <LoadingOverlay
            active={!isSuccess}
            spinner
            text='Loading your content...'
          >
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-5">
                    <h4 className="card-title mb-3">
                      &#8226; Minimum App Version :
                    </h4>
                    <ul className="list-inline d-flex align-items-start">
                      <li className="list-inline-item">
                        <Label className="form-label">Android</Label>
                        <Input
                          name="android_v"
                          placeholder="Enter Value..."
                          type="text"
                          autoComplete="off"
                          inputMode="numeric"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.android_v || ""}
                          invalid={
                            validation.touched.android_v &&
                              validation.errors.android_v
                              ? true
                              : false
                          }
                        />
                        {validation.touched.android_v &&
                          validation.errors.android_v ? (
                          <FormFeedback type="invalid">
                            {validation.errors.android_v}
                          </FormFeedback>
                        ) : null}
                      </li>
                      <li className="list-inline-item">
                        <Label className="form-label">iOS</Label>
                        <Input
                          name="ios_v"
                          placeholder="Enter Value..."
                          type="text"
                          autoComplete="off"
                          inputMode="numeric"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ios_v || ""}
                          invalid={
                            validation.touched.ios_v && validation.errors.ios_v
                              ? true
                              : false
                          }
                        />
                        {validation.touched.ios_v && validation.errors.ios_v ? (
                          <FormFeedback type="invalid">
                            {validation.errors.ios_v}
                          </FormFeedback>
                        ) : null}
                      </li>
                    </ul>
                  </div>

                  <div className="mb-5">
                    <h4 className="card-title mb-3">
                      &#8226; Customer Support WhatsApp:
                    </h4>
                    <div class="mb-3">
                      <div class="row g-3 align-items-center">
                        <div class="w-125px col-auto">
                          <label for="whatsappIndonesia" class="col-form-label"><img src="https://flagcdn.com/w20/id.png" alt="Indonesia flag"/>  Indonesia</label>
                        </div>
                        <div class="col-auto">
                          <Input
                              name="whatsappIndonesia"
                           
                              type="text"
                              autoComplete="off"
                              inputMode="tel"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.whatsappIndonesia || ""}
                              className="col-form-label"
                              invalid={
                                validation.touched.whatsappIndonesia &&
                                  validation.errors.whatsappIndonesia
                                  ? true
                                  : false
                              } />
                        </div>
                      </div>
                      <div class="row g-3 align-items-center pt-1">
                        <div class="w-125px col-auto">
                          <label for="whatsappSingapore" class="col-form-label"><img src="https://flagcdn.com/w20/sg.png" alt="Singapore flag"/> Singapore</label>
                        </div>
                        <div class="col-auto">
                        <Input
                              name="whatsappSingapore"
                            
                              type="text"
                              autoComplete="off"
                              inputMode="tel"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.whatsappSingapore || ""}
                              className="col-form-label"
                              invalid={
                                validation.touched.whatsappSingapore &&
                                  validation.errors.whatsappSingapore
                                  ? true
                                  : false
                              } />
                        </div>
                      </div>
                      {/* 
                      malaysia customer support

                      <div class="row g-3 align-items-center pt-1">
                        <div class="w-125px col-auto">
                          <label for="whatsappMalaysia" class="col-form-label"><img src="https://flagcdn.com/w20/my.png" alt="Malaysia flag"/> Malaysia</label>
                        </div>
                        <div class="col-auto">
                        <Input
                              name="whatsappMalaysia"
                             
                              type="text"
                              autoComplete="off"
                              inputMode="tel"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.whatsappMalaysia || ""}
                              className="col-form-label"
                              invalid={
                                validation.touched.whatsappMalaysia &&
                                  validation.errors.whatsappMalaysia
                                  ? true
                                  : false
                              } />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-2 justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      disabled={validation.isSubmitting}
                    >
                      {validation.isSubmitting ? "Submitting..." : "Submit"}
                    </Button>{" "}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Col>
      </Row>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          onConfirm={handleConfirm}
          toggle={handleCancel}
          data={modalData}
          type={"general"}
          rawData={rawData}

        />
      )}
    </React.Fragment>
  )
}

export default GeneralSettings
