import React from 'react';
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"
import iMage from "./../assets/images/gallery/img6.png"
const ReloadModal = ({ onReload }) => (

<>
<Modal isOpen={true} centered>
                <div class="mt-0  modal-header"><h5 class="modal-title text-center w-100">Important Announcement</h5></div>
                <ModalBody>
                    <Form
                      >
<center>    <img src={iMage} width="150px" />
    </center>                    
                        <h5>🎉A newer Dashboard is available, please reload! 🎉</h5>
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="button" onClick={onReload}  color="primary" >
                                Reload
                            </Button>{" "}
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
);

export default ReloadModal;