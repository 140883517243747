import React, { useState } from "react"
import moment from "moment"
// import UserNotificationModal from "./modals/UserReadNotificationModal";

import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from "primereact/skeleton";
import { InputText } from 'primereact/inputtext';
import { debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE } from "constants/value";
import usePromotion from "hooks/usePromotion";
import TransactionHistoryModal from "./modals/TransactionHistoryModal";

const PartnerPromotionTable = ({ loading, id, lazyState, setlazyState, refresh, setRefresh, promoTable, setPromoTable, totalRecords, setTotalRecords, totalRewards, setTotalRewards }) => {
    const { countryList } = usePromotion();

    const items = Array.from({ length: lazyState.config.rows }, (v, i) => i);
    const delay = 300;
    const [search, setSearch] = useState(id || '')
    // Create a debounced search function

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const incrementId = (data, props) => {
        return props.rowIndex + 1;
    }
    // const totalReward = (data) => {
    //     return balanceFormatter(data.totalReward || 0, 'IDR')
    // }

    const countryData = (data) => {
        const countryObject = countryList.find(item => item.value === data.currency);
        const label = countryObject ? countryObject.label : '-';

        return label
    }
    const totalReward = (data) => {
        return <TransactionHistoryModal data={data} options={lazyState} />
    }
    const columns = [
        { field: '', header: '#', body: incrementId, className: 'text-end' },
        { field: 'name', header: 'Name', body: '' },
        { field: '', header: 'Country', body: countryData },
        { field: 'totalReward', header: 'Total Reward', body: totalReward, sortable: true },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                                <h3>Total Partner Reward :{balanceFormatter(totalRewards || 0, 'IDR')}</h3>
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Name" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    {/* <UserExportCSV lazyState={lazyState} /> */}
                                </div>
                            </Col>
                        </Row>

                        <DataTable
                            removableSort
                            // lazy
                            value={loading ? items : promoTable}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                            size={'normal'}
                            // onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            dataKey={loading ? '' : '_id'}
                            defaultSortOrder={-1}
                        >
                            {columns.map((col, i) => (
                                <Column sortable={col.sortable} key={col.field} field={col.field} header={col.header} bodyClassName={col.className} body={loading ? <Skeleton> </Skeleton> : col.body} />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PartnerPromotionTable
