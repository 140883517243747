import React, { useEffect, useState } from "react";
import { Button, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Form, FormGroup } from "reactstrap"

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import InputMask from "react-input-mask";

import { post, del, get, put } from "../../../../../helpers/api_helper";
import deleteIcon from "../../../../../assets/images/delete-icon.png"

import '../../../../../assets/css/style.css'
import useAuth from "hooks/useAuth";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import makeid from "helpers/random";
import useAsset from "hooks/useAsset";
import { InputSwitch } from 'primereact/inputswitch';
import AddressForm from "pages/AddressForm";
const AddModal = () => {
    const axiosApi = useAxiosPrivate();
    const [modal_center, setmodal_center] = useState(false);
    const { setRefresh, refresh } = useAsset();
    const { showToast } = useAuth();
    const [selectedStatus, setSelectedStatus] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedPostcode, setSelectedPostcode] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [uploading, setUploading] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    const [defaultId, setDefaultId] = useState(null);

    const getDefaultID = async () => {
        try {
            const response = await axiosApi.get(`/api/erp/asset/companies/get-code`);
            setDefaultId(response.data.results.company_code)
        } catch (error) {
            if (error.response && error.response.data && error.response.data.state) {
                showToast(error.response.data);
                validation.setErrors(error.response.data);
            } else {
                let response = {};
                response.state = "error";
                response.toast = true;
                response.message = "Internal Server Error"
                showToast(response);
            }
        }
    }

    const storeFiles = async e => {
        const { files } = e.target
        const validFiles = Array.from(files)
        setUploading(true)
        await Promise.all(
            validFiles.map(async photo => {
                const formData = new FormData()
                formData.append("image", photo)
                const response = await axiosApi.post(
                    "/api/erp/asset/companies/upload",
                    formData,
                    {
                        headers: {
                        "Content-Type": "multipart/form-data",
                        },
                    }
                )
                setImageUrl(response.data.url)
            })
        )
        setUploading(false)
    }
    
    const deleteImage = e => {
        setImageUrl("")
    }

    useEffect(() => {
        if (modal_center) {
            getDefaultID();
        }
    }, [refresh, modal_center]);
    const tog_center = () => {
        setmodal_center(!modal_center);
        removeBodyCss();
        validation.resetForm();
    };
    const handleIdChange = (event) => {
        let { name, value } = event.target;
        const uppercaseValue = value ? value.toUpperCase() : '';

        setDefaultId(uppercaseValue);
        validation.setFieldValue(name, uppercaseValue); // Update formik's value

    };
    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };
    const phoneRegExp = /^[\+0-9]?[1-9]{1,4}[ \-]*(\([0-9]{2,3}\)[ \-]*|[0-9]{2,4}[ \-]*)*[0-9]{3,4}[ \-]*[0-9]{3,4}?$/

    // Form validation 
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            company_code: defaultId??"",
            name: "",
            legal_name: "",
            phone: "",
            address: "",
            postcode: "",
            city: "",
            tax_number: "",
            contact_name: "",
            contact_email: "",
            contact_phone: "",
        },
        validationSchema: Yup.object().shape({
            company_code: Yup.string().required("Company ID is required"),
            name: Yup.string().required("Company name is required"),
            // legal_name: Yup.string().required("Legal name is required"),
            phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone is required'),
            // tax_number: Yup.string().required("Tax number is required"),
            contact_name: Yup.string().required("Contact name is required"),
            contact_email: Yup.string()
                .email("Invalid email address")
                .required("Contact email is required"),
            contact_phone: Yup.string().required("Contact number is required").matches(phoneRegExp, 'Phone number is not valid'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const opts = { ...values, is_active: selectedStatus, location_city_id: selectedCity.id, location_region_id: selectedRegion.id, location_country_id: selectedCountry.id, address: selectedAddress, postcode: selectedPostcode, tax_card_image_url: imageUrl };
                console.log('opts', opts);
                const response = await post('/api/erp/asset/companies', opts);
                setRefresh(makeid(5));
                resetForm();
                tog_center();
                showToast(response);
            } catch (error) {
                if (error.response && error.response.data && error.response.data.state) {
                    showToast(error.response.data);
                    validation.setErrors(error.response.data);
                } else {
                    let response = {};
                    response.state = "error";
                    response.toast = true;
                    response.message = "Internal Server Error"
                    showToast(response);
                }
            }
        }
    });

    return (
        <>
            <div className="my-2">
                <Button color="primary" onClick={tog_center}> Add Company </Button>
            </div>
            <Modal isOpen={modal_center} toggle={tog_center} centered >
                <ModalHeader className="mt-0" toggle={tog_center}>Add New Company</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>

                        {/* Company ID */}
                        <div className="mb-3">
                            <Label className="form-label">Company ID</Label>
                            <InputMask
                                placeholder="Enter Company ID"
                                name="company_code"
                                mask="aaaa-aa-aa-99999"  // Mask for date format
                                value={defaultId}  // Controlled input value
                                onChange={handleIdChange}  // Update state on change
                                invalid={validation.touched.company_code && validation.errors.company_code}
                                maskChar={null}  // Removes default mask characters (like "_")
                            >
                                {(inputProps) => <Input {...inputProps} id="masked-input" type="text" />}
                            </InputMask>
                            {validation.touched.company_code && validation.errors.company_code ? (
                                <FormFeedback type="invalid">{validation.errors.company_code}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Company Name */}
                        <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <Input
                                name="name"
                                placeholder="Enter Company Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={validation.touched.name && validation.errors.name}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Phone */}
                        <div className="mb-3">
                            <Label className="form-label">Phone</Label>
                            <Input
                                name="phone"
                                placeholder="Enter Phone"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={validation.touched.phone && validation.errors.phone}
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Contact Name */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Name</Label>
                            <Input
                                name="contact_name"
                                placeholder="Enter Contact Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contact_name || ""}
                                invalid={validation.touched.contact_name && validation.errors.contact_name}
                            />
                            {validation.touched.contact_name && validation.errors.contact_name ? (
                                <FormFeedback type="invalid">{validation.errors.contact_name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Contact Email */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Email</Label>
                            <Input
                                name="contact_email"
                                placeholder="Enter Contact Email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contact_email || ""}
                                invalid={validation.touched.contact_email && validation.errors.contact_email}
                            />
                            {validation.touched.contact_email && validation.errors.contact_email ? (
                                <FormFeedback type="invalid">{validation.errors.contact_email}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Contact Number */}
                        <div className="mb-3">
                            <Label className="form-label">Contact Number</Label>
                            <Input
                                name="contact_phone"
                                placeholder="Enter Contact Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contact_phone || ""}
                                invalid={validation.touched.contact_phone && validation.errors.contact_phone}
                            />
                            {validation.touched.contact_phone && validation.errors.contact_phone ? (
                                <FormFeedback type="invalid">{validation.errors.contact_phone}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Legal Name */}
                        <div className="mb-3">
                            <Label className="form-label">Legal Name</Label>
                            <Input
                                name="legal_name"
                                placeholder="Enter Legal Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.legal_name || ""}
                                invalid={validation.touched.legal_name && validation.errors.legal_name}
                            />
                            {validation.touched.legal_name && validation.errors.legal_name ? (
                                <FormFeedback type="invalid">{validation.errors.legal_name}</FormFeedback>
                            ) : null}
                        </div>
                        {/* Tax Number */}
                        <div className="mb-3">
                            <Label className="form-label">Tax Number</Label>
                            <Input
                                name="tax_number"
                                placeholder="Enter Tax Number"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tax_number || ""}
                                invalid={validation.touched.tax_number && validation.errors.tax_number}
                            />
                            {validation.touched.tax_number && validation.errors.tax_number ? (
                                <FormFeedback type="invalid">{validation.errors.tax_number}</FormFeedback>
                            ) : null}
                        </div>
                        <AddressForm
                            refresh={modal_center}
                            selectedCountry={selectedCountry}
                            selectedCity={selectedCity}
                            selectedRegion={selectedRegion}
                            selectedPostcode={selectedPostcode}
                            selectedAddress={selectedAddress}
                            setSelectedCountry={setSelectedCountry}
                            setSelectedCity={setSelectedCity}
                            setSelectedRegion={setSelectedRegion}
                            setSelectedPostcode={setSelectedPostcode}
                            setSelectedAddress={setSelectedAddress}
                            countryId={''}
                            regionId={''}
                            cityId={''}
                        />
                        {/* Status And NPWP */}
                        <div className="mb-3 row">
                            {/* Status Column */}
                            <div className="col-md-2">
                                <Label className="form-label">Status</Label><br />
                                <InputSwitch
                                    checked={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.value)}
                                />
                            </div>

                            {/* NPWP Image and Delete Icon */}
                            <div className="col-md-6 d-flex flex-column align-items-end">
                                <Label className="form-label text-end">NPWP</Label>
                                {imageUrl !== "" && (
                                    <div className="image-container position-relative">
                                        <img
                                            src={deleteIcon}
                                            className="box-delete-icon position-absolute"
                                            style={{ top: "0", right: "0", cursor: "pointer" }}
                                            width={28}
                                            height={28}
                                            onClick={deleteImage}
                                            alt="delete"
                                        />
                                        <img
                                            src={imageUrl}
                                            width={150}
                                            className="box-image"
                                            alt="documentation"
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Upload Button and Input */}
                            <div className="col-md-4 d-flex flex-column align-items-end">
                            <Label className="form-label text-end">&nbsp;</Label>
                                <label
                                    htmlFor="fileInput"
                                    className="btn btn-primary"
                                >
                                    {imageUrl === "" ? "Upload" : "Replace"} NPWP
                                </label>
                                {uploading && (
                                    <Label className="form-label text-danger">
                                        uploading...
                                    </Label>
                                )}
                                <input
                                    className="d-none"
                                    id="fileInput"
                                    type="file"
                                    multiple
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={storeFiles}
                                    name="image"
                                />
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="d-flex flex-wrap gap-2 justify-content-end">
                            <Button type="submit" color="primary" disabled={validation.isSubmitting}>
                                Submit
                            </Button>{" "}
                        </div>

                    </Form>
                </ModalBody>
            </Modal>
        </>
    )

}

export default AddModal
