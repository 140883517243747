import { useState, useRef } from "react";

import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import moment from "moment";
import { Link } from "react-router-dom";
import { simplify, startTime, endTime, debounce, balanceFormatter } from "helpers/functions";
import { ROWS_PER_PAGE_MEDIUM_DASHBOARD } from "constants/value";
import PickupQrModal from "./modals/PickupQrModal";
import UserDetailModal from "./modals/UserDetailModal";

const PickupsTable = ({ id, pickupDatas, totalRecords, lazyState, setlazyState, loading, setRefresh, expandedRows, setExpandedRows }) => {
    const toast = useRef(null);
    const [search, setSearch] = useState(id||'')
    const items = Array.from({ length: lazyState.config.rows }, (v, i) => {
        return {
            UCO_Approved: true
        }
    }); //prevent placeholder to have red background
    const delay = 300;

    const addressData = (data) => {
        return data.address.name + "," + data.address.full_address + "," + data.address.city
    }

    const roundedVol = (data) => {
        return simplify(data.uco_volume)
    }
    const roundedWeight = (data) => {
        return simplify(data.uco_weight)
    }

    const moreThanADay = (createdAt) => {
        const createdDate = new Date(createdAt); // Parse `createdAt` to a Date object
        const now = new Date();
        const oneDayInMs = 24 * 60 * 60 * 1000; // One day in milliseconds
      
        return now - createdDate < oneDayInMs;
    }
    const adminAction = (data) => {
        if ( (data.platform != "dash" && data.grab_status == 'COMPLETED' && data.status == 'pending' ) || (data.platform == "dash" && (data.grab_status == "IN_DELIVERY" || (data.grab_status == "COMPLETED" && moreThanADay(data.createdAt)) )  )) {
            return (
                <div className="d-flex flex-row gap-2">
                    <div>
                        <PickupQrModal pickup={data} setRefresh={setRefresh} />
                    </div>
                </div>
            )
        } else if (data?.status == "success"){
            if (data?.trx?.UCO_Approved) {
                return "Accepted"
            } else {
                return "Rejected"
            }
        } else {
            return "-"
        }

    }
    const userDetail = (data) => {
        return (
            <div className="d-flex flex-row gap-2">
                <div>
                    <UserDetailModal user={data} />
                </div>
            </div>
        )
    }

    const copyToClipboard = async data => {
        try {
            const id = data.pickup_id;
            const { protocol, hostname, port } = window.location;

            const pathname = window.location.pathname; // e.g., "/accounting/3231-0105" or "/accounting/"
            const segments = pathname.split('/').filter(segment => segment !== ''); // Filter out empty segments
            let basepath;
            if (segments.length > 0) {
                // Take the first segment and construct the base path
                basepath = `/${segments[0]}/`;
            } else {
                basepath = '/'; // In case of an empty path
            }
            const fullBaseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}${basepath}${id}`;
            await navigator.clipboard.writeText(fullBaseUrl);
            toast.current.show({ severity: 'info', summary: 'Link copied to clipboard' });
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };
    const rowClassName = (rowData) => {
        if (rowData.status == 'cancelled') {
            return 'redBg';
        } else if (rowData.active == '3') {
            return 'warningBg';
        }

        //will be {'greyed': !rowData.active} but this is for demonstration
    }
    const transactionAmount = (data) => {
        return balanceFormatter(data.price)
    }
    const driverData = (data) => {
        return <div>
            <div style={{ display: 'flex' }}>
                <strong style={{ marginRight: '10px' }}>
                    <span className="text-capitalize">{data.platform}</span>
                    <br />
                    ({data.driver != null ? data.driver.name : '-'})
                </strong>
            </div>
            {/* <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold', color: 'gray' }}>
                    {data.driver.phone}
                </span>{" "}
            </div>
            <div style={{ display: 'flex' }}>
                <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                    {data.driver.licensePlate}
                </span>{" "}
            </div> */}
        </div>;
    }

    const headerDriver = () => {
        return <>
            <span>Courier<br />(Driver)</span>
        </>
    }

    const createdFormat = (data) => {
        return moment(data.createdAt).format('MM/DD/YYYY HH:mm:ss')
    }
    const columns = [
        { field: 'pickup_id', header: 'Pick Up ID', body: '', className: '', style: { minWidth: '120px' } },
        { field: 'address.name', header: 'Sender', body: userDetail, className: '' },
        // { field: 'address.full_address', header: 'Address', body: '', className: '', style: { maxWidth: '300px' } },
        { field: 'driver', header: headerDriver, body: driverData, className: '', style: { minWidth: '130px' } },
        { field: 'uco_volume', header: 'Volume (liter)', body: roundedVol, className: 'text-center' },
        // { field: 'uco_weight', header: 'Weight (kg)', body: roundedWeight, className: 'text-end' },
        { field: 'grab_status', header: 'Status', body: '', className: '' },
        // { field: 'status', header: 'Status', body: '', className: '' },
        { field: 'price', header: 'Fare', body: transactionAmount, className: 'text-start' },
        { field: 'created_at', header: 'Date', body: createdFormat, className: 'text-start' },
        { field: 'action', header: 'UCO Status', body: adminAction, style: { minWidth: '150px' } },
    ]

    const onPage = (event) => {
        let _filters = { ...lazyState };
        _filters.config = event;
        setlazyState(_filters);
    }

    const onRowToggle = (e) => {
        setExpandedRows(e.data);
    }

    const changeFilter = (value) => {
        let _filters = { ...lazyState };
        _filters.searchText = value;
        setlazyState(_filters)
    }

    // Create a debounced search function
    const debouncedSearch = debounce(changeFilter, delay);

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    }

    const rowExpansionTemplate = (data) => {
        // console.log(data)
        return (
            <div className="trans-detail">
                <h5>Pick Up ID : {data.pickup_id}</h5>
                <div className="d-flex flex-row justify-content-around p-3">
                    <div className="col-5">
                        <ul>

                            <li>Merchant Order ID : {data.mo_id}</li>
                            <li>Status: {data.status === 'success' ? (data.trx != null ? `accepted (${data.trx.TA_UCO_Volume} liter / ${balanceFormatter(data.trx.totalAmount, 'IDR')} )` : 'accepted') : data.status}</li>
                            <li>Delivery Status: {data.grab_status ?? "Unknown"}</li>
                        </ul>
                        <label>Transaction :</label>
                        <ul>
                            <li>Platform ID : {data.gd_id ? data.gd_id : '-'}</li>
                            <li>Fare : {balanceFormatter(data.price)}</li>
                            <li>Volume : {simplify(data.uco_volume)} liter</li>
                            <li>Weight : {simplify(data.uco_weight)} kg</li>
                            <li>Distance : {simplify(data.distance / 1000)} km</li>
                            <li>Status : {data.grab_status}</li>
                            {(data.status == 'pending' && data.grab_link) && 
                                <a href={data.grab_link} target="_blank"><button class="btn btn-primary btn-sm">Tracking Link</button></a>
                            }
                            {/* <li>Grab live tracking : {data.grab_link !== "" && data.grab_status != 'COMPLETEDx' ? (
                                <a target="_blank" href={data.grab_link}>click here</a>
                            ) : (
                                '-'
                            )}</li> */}
                        </ul>
                        {data.schedule != null &&
                        <>
                        <label>Schedule:</label>
                        <ul>
                            <li>Date : {data.schedule?.date ?? "-"}</li>
                            <li>Time : {data.schedule?.start ?? "-"} - {data.schedule?.end ?? "-"}</li>
                        </ul>
                        </>
                        }
                    </div>
                    <Divider layout="vertical" />
                    <div className="col-7">
                        <label>Sender</label>
                        <ul>
                            <li>Name : {data.address.name}</li>
                            <li>Pick Up Location : {data.address.full_address}, {data.address.city},{data.address.postal_code} </li>
                            <li>Pick Up Coordinates <a target="_blank" href={`https://www.google.com/maps/search/${data.address.latitude},${data.address.longitude}`}>
                                {data.address.latitude}, {data.address.longitude}</a>
                            </li>
                        </ul>
                        {data.driver != null ? (
                            <div>
                                <label>Driver</label>
                                <ul>
                                    <li className="text-capitalize">Platform: {data.platform ?? '-'}</li>
                                    <li>Name: {data.driver.name ?? '-'}</li>
                                    <li>Phone: {data.driver.phone ?? '-'}</li>
                                    <li>Plate No: {data.driver.licensePlate ?? '-'}</li>
                                    <li>Model: {data.driver.model ?? '-'}</li>
                                </ul>
                            </div>
                        ) : null}
                        <Toast ref={toast} />
                        <div className="my-2">
                            <Button color="info"
                                onClick={() => {
                                    copyToClipboard(data)
                                }} >
                                Copy Link
                            </Button>
                        </div>
                        <label>UCO Transactions:</label>
                        <ul>
                         {/* foreach transactions */}
                         {data.transactions?.length > 0 ? (
                                <>
                                    {data.transactions.map((item, index) => (
                                        <li key={index}>
                                            <Link to={'/transactions'} state={item?.TA_ID}>
                                                {item?.TA_ID}
                                            </Link>
                                            <> ({item?.TA_UCO_Volume} liter / {balanceFormatter(item?.totalAmount, 'IDR')})</>
                                        </li>
                                    ))}
                                </>
                            ) : (
                                <li>{data.trx !== null ? <><Link to={'/transactions'} state={data.trx.TA_ID}> {data.trx.TA_ID} </Link> ({data.trx?.UCO_Approved ? <>`{data.trx.TA_UCO_Volume} liter / {balanceFormatter(data.trx.totalAmount, 'IDR')} `</>  : "rejected"}) </>  : '-'}</li>
                     
                        )}
                        </ul>
                   
                        {data.errorReason != null &&
                        <>
                        <label>Failed Reason:</label>
                        <ul>
                            <li><span class="text-danger">{data.errorReason ?? ""}</span></li>
                        </ul>
                        </>
                        }
                    </div>

                </div>
            </div>
        );
    };

    return (
        <Row>
            <Col xl={12}>
                <Card >
                    <CardBody>
                        <Row className="align-items-center mb-2">
                            <Col md={6} className="d-flex flex-row align-items-center gap-3">
                            </Col>
                            <Col md={6}>
                                <span className="p-input-icon-left float-start float-md-end">
                                    <i className="pi pi-search" />
                                    <InputText value={search} onChange={onSearchTextChange} placeholder="Pick Up ID / Sender" className='p-inputtext-sm user-filter' style={{ 'width': '240px' }} />
                                </span>
                            </Col>
                            {/* <Col md={1}>
                                <div className="float-start d-block d-flex flex-row gap-2 export-icon">
                                    <TransactionExportCSV lazyState={lazyState} />
                                </div>
                            </Col> */}
                        </Row>

                        <DataTable
                            lazy
                            value={loading ? items : pickupDatas}
                            stripedRows
                            paginator
                            rows={lazyState.config.rows}
                            rowsPerPageOptions={ROWS_PER_PAGE_MEDIUM_DASHBOARD}
                            size={'normal'}
                            onPage={onPage}
                            totalRecords={totalRecords}
                            first={lazyState.config.first}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} requests"
                            expandedRows={loading ? null : expandedRows}
                            rowExpansionTemplate={rowExpansionTemplate}
                            onRowToggle={onRowToggle}
                            rowClassName={rowClassName}
                            dataKey={loading ? '' : 'pickup_id'}
                        >
                            <Column expander={true} style={{ width: '3rem' }} />
                            {columns.map((col, i) => (
                                <Column
                                    style={col.style}
                                    key={col.field}
                                    field={col.field}
                                    header={col.header}
                                    body={loading ? <Skeleton></Skeleton> : col.body}
                                    bodyClassName={col.className}
                                />
                            ))}
                        </DataTable>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default PickupsTable