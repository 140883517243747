import React, { useEffect, useState } from "react";
import AdminTable from "./AdminTable";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useAdmin from "hooks/useAdmin";
import useAuth from "hooks/useAuth";
import { useParams, useLocation } from "react-router-dom";

const AdminList = () => {
  const axiosApi = useAxiosPrivate();
  const { refresh, setAdmins, setAdminLoading } = useAdmin();
  const { auth } = useAuth();
  const location = useLocation();
  const propsData = location.state;
  const { id } = useParams();

  const [lazyState, setLazyState] = useState({
    searchText: propsData || id || '',
    config: {
      filters: {
        name: { value: '', matchMode: 'contains' },
      }
    }
  });

  useEffect(() => {
    setAdminLoading(true);
    let isMounted = true;
    const controller = new AbortController;

    const getAdmins = async () => {
      setAdminLoading(true);
      try {
        const response = await axiosApi.get('/api/admin/getAdmins', {
          signal: controller.signal,
        });
        isMounted && setAdmins(response.data);
      } catch (err) {
        console.log(err);
      }
      setAdminLoading(false);
    }

    getAdmins();

    return () => {
      isMounted = false,
        controller.abort;
    }
  }, [refresh, lazyState]);

  return (
    <React.Fragment>
          <AdminTable idParam={id} id={propsData} lazyState={lazyState} setLazyState={setLazyState} />
    </React.Fragment>
  );
};

export default AdminList;
